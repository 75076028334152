.hamburger:hover {
  cursor: pointer;
}

.hamburger span:nth-child(1) {
  top: 9px;
}

.hamburger span:nth-child(2), .hamburger span:nth-child(3) {
  top: 18px;
}

.hamburger span:nth-child(4) {
  top: 27px;
}

.hamburger.open span:nth-child(1) {
  top: 18px;
  width: 0;
  left: 50%;
}

.hamburger.open span:nth-child(2) {
  transform: rotate(45deg);
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.hamburger.open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}

.hamburger {
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  background-color: #fefefe;
  border: 0;
  display: none;
  width: 28px;
  height: 44px;
  margin: 6px 12px 0 !important;
}

.hamburger span {
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  background-color: #4a4a4a;
  height: 3px;
  margin-bottom: 5px;
  width: 100%;
}

.hamburger:focus, .hamburger:visited {
  outline: none;
}

.header-main {
  width: 100%;
  z-index: 999;
  height: 55px;
  position: fixed;
  top: 40px;
}

.header-main .logo {
  background: #fafafa;
  height: 70px;
  align-items: center;
  justify-content: center;
  padding: 0 32px 0 22px;
  border-radius: 0 0 40px 0;
  z-index: 9;
}

.header-tg .logo {
  margin: 6px 0 0 25px;
}

.bar-right .login-btn {
  background: none;
  border-right: none !important;
}

.bar-right .login-btn::after {
  display: none;
}

.header-main .logo img {
  width: 100px;
  height: 100%;
}

.header-tg .logo img {
  width: 100px;
}

.header-main .nav .nav-item {
  font-size: 15px;
  transition-duration: 0.3s;
  padding: 0 30px;
}

.header-tg .nav .nav-item {
  font-size: 16px;
  padding: 0 40px;
}

.header-main .nav {
  height: 55px;
}

.header-main .nav-item:hover {
  cursor: pointer;
}

.nav-item span, .bar-right a span.context_other {
  font-size: 14px;
  margin-top: 1px;
}

.header-main .nav a:hover {
  text-decoration: none;
}

.header-main .dropdown-lang_content a:hover {
  background-color: #f4f7fc;
}

.header-height {
  height: 55px;
  /*line-height: 55px;*/
}

.header-topbar {
  height: 40px;
  font-size: 13px;
  z-index: 1000;
}

.header-topbar .link {
  text-decoration: underline;
  text-decoration-color: #fff;
}

.right-items {
  right: 0;
}

.left-items span, .left-items span:hover {
  transition-duration: 0.3s;
}

.dropdown-lang_content, .dropdown-mobile_content, .dropdown_content {
  display: none;
  z-index: 1;
  top: 55px;
}

.dropdown-context_content {
  top: 40px;
  right: 0;
  min-width: 150px;
  overflow-y: auto;
  max-height: calc(100vh - 90px);
}

.dropdown-context:hover, .dropdown-lang {
  cursor: pointer;
}

.dropdown-lang_content a, .dropdown-context_content a {
  padding: 12px 16px;
  text-decoration: none;
  font-size: 14px;
}

.dropdown-lang_content a:not(:last-of-type), .dropdown-context_content a:not(:last-of-type) {
  border-bottom: 1px solid #f0f0f0 !important;
}

.dropdown-lang_content a {
  font-size: 12px;
}

.dropdown-context_content a:last-of-type {
  border-bottom: none;
}

.dropdown-lang_content img {
  width: 22px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  vertical-align: middle;
}

a.text-success:hover {
  color: #ff4500 !important;
  text-underline: none !important;
}

.lang {
  font-size: 11px;
}

.nav .nav-item {
  overflow: hidden;
  color: inherit;
  text-decoration: none;
}

.bar-right .trainor-admin {
  height: 40px;
  transition-duration: 0.3s;
}

.bg-secondary .bar-right a {
  color: #fff !important;
}

.bar-right .trainor-admin:hover {
  background-color: #fff;
  transition-duration: 0.3s;
}

.bar-right .trainor-admin:hover a {
  color: #2a4458 !important;
  transition-duration: 0.3s;
}

.bar-right a {
  padding: 0 20px;
}

.dropdown-context_content a {
  padding: 12px;
}

.logout-btn, .login-btn {
  border-bottom: none !important;
}

.btn-log {
  bottom: 60px;
  width: 95%;
  left: 50%;
  transform: translate(-50%, 0);
}

.register-wrap {
  bottom: 20px;
}

.right-items .right-item_img img, .mobile-menu img {
  width: 20px;
  height: 20px;
  vertical-align: text-top;
}

.right-items .right-item_img img.current-flag {
  height: auto;
}

.dropdown-lang_label {
  font-size: 13px;
  line-height: 15px;
  margin-top: 22px;
}

.right-items .right-item_img, .mobile-menu img {
  vertical-align: middle;
}

.right-items .right-item {
  padding: 0 20px;
  text-decoration: none;
}

.right-items .right-item_label {
  font-size: 12px;
}

.right-items .search-item {
  border-right: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
}

.lang-item .right-item_img {
  vertical-align: sub;
}

.search-input {
  height: 28px;
  border: none;
  border-bottom: 1px solid #bbbfc3;
  display: none;
  margin-right: 10px;
}

.search-input::placeholder {
  color: #B9C3CE;
}

.header-transparent .search-input::placeholder {
  color: #fff;
  font-weight: 300;
}

.header-transparent .search-input {
  background-color: transparent;
  color: #fff;
}

.header-transparent.header-eu {
  display: none;
}

.search-input:focus {
  outline: none;
}

.search-item:hover {
  cursor: pointer;
}

.right-items .right-item.lang-item {
  padding-right: 35px;
}

.img-shadow {
  box-shadow: -1px -1px 10px #d0d0d0;
}

.overflow-hidden {
  overflow: hidden;
}

.mobile-bottom {
  flex-direction: row;
  bottom: 0;
  z-index: 2;
  display: none;
}

.mobile-items .nav-item {
  font-size: 10px;
}

.mobile-bottom img {
  width: 14px;
}

.icon-wrap {
  height: 50px;
  width: 24%;
}

#overlay {
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  z-index: 3;
  display: none;
}

.dropdown-mobile_content {
  display: none;
  z-index: 999;
  overflow: scroll;
  top: 90px;
  font-size: 16px;
  height: calc(100% - 90px);
}

.dropdown-mobile_content a:not(.btn), .mobile-nav-tab {
  height: auto;
  border-bottom: 1px solid #f1f1f1;
}

.dropdown-mobile_content .nav-drop a {
  border-bottom: 0 !important;
}

.dropdown-mobile_content.collapsed {
  display: block;
}

.dropdown-mobile_content .nav-drop_icon {
  position: absolute;
  right: 20px;
}

.logout-wrap {
  position: absolute;
  width: 100%;
  bottom: 20px;
}

.logout-wrap.active {
  position: initial;
}

.nav-drop_icon.active i::before {
  content: "\f0de";
}

.dropdown-mobile_content .nav-drop_icon.active {
  top: 15px;
}

.nav-drop {
  display: none;
}

.dropdown-mobile_content .nav-context {
  background-color: #d2eaff;
}

.mobile-menu {
  z-index: 9;
  display: inline-block;
  background-color: #fff;
}

.right-items.active {
  line-height: 33px;
}

.nav-drop .last {
  border-bottom: 5px solid #eff4f8;
}

.dropdown-mobile_content a:hover, .dropdown-mobile_content a:focus, .mobile-items a {
  text-decoration: none;
}

.nav-lang.active, .nav-mypage.active, .nav-mycompany.active, .nav-about.active {
  background: #eff4f8;
}

.nav-drop_lang a .mobile-lang {
  color: #003d64;
}

.mobile-lang {
  padding-left: 6px;
  font-size: 16px;
}

.left-items .dropdown span.icon {
  padding-left: 5px;
  padding-right: 0;
}

.left-items .nav-item, .cart_amount, .nav-drop_context a {
  color: #2a4458;
}

.icon-wrap:hover {
  background-color: #225683;
}

.search-input.small {
  width: 100px;
  font-size: 12px;
}

.right-items .login-item {
  padding: 8px 20px 8px 15px;
}

.bar-right, .bar-left {
  display: inline-flex;
  align-items: center;
  width: auto;
  height: 40px;
}

.bar-right {
  position: absolute;
  right: 0;
  font-size: 14px;
  top: 0;
}

.bar-right div:not(.dropdown_content) {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.dropdown-context::after, .dropdown_content::after {
  display: none;
}

.bar-right a:not(.logout-btn) {
  text-decoration: none;
}

.left-items .dropdown_sector span, .left-items .dropdown_child span {
  transition-duration: 0.3s;
  height: 45px;
  line-height: 43px;
}

.header-tg .left-items .dropdown_child span {
  height: 55px;
  line-height: 55px;
}

.left-items .dropdown_sector.active, .left-items .dropdown_child.active, .left-items .dropdown_school.active {
  display: inline-flex !important;
  flex-direction: column;
}

.left-items .dropdown_sector span a, .left-items .dropdown_school span a {
  font-size: 14px;
}

.left-items .nav-item:hover, .left-items .nav-item.active {
  background-color: #f4f6f7;
  transition-duration: 0.3s;
}

.header-tg .left-items .dropdown .nav-item:hover {
  background-color: #fff;
}

.header-tg .dropdown_child {
  position: absolute;
  top: 55px;
  width: max-content;
}

.header-tg .dropdown_child span {
  padding: 0 30px !important;
}

.header-topbar.hide-header {
  position: initial;
}

.mobile {
  display: none;
}

.bg-header {
  height: 90px;
}

.text-underline {
  text-decoration: underline !important;
}

.line-height-less {
  line-height: 1.2;
}

.left-items .dropdown_systems a {
  color: #292929;
}

.dropdown_services, .dropdown_systems, .dropdown_learning, .dropdown_courses, .dropdown_school {
  left: 0;
  top: 55px;
  height: 425px;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 6px 10px -10px #d5d5d5;
}

#services-tab:hover .dropdown_services, #learning-tab:hover .dropdown_learning, #systems-tab:hover .dropdown_systems, #courses-tab:hover .dropdown_courses, #school-tab:hover .dropdown_school {
  transition: visibility 0s linear 0.15s, opacity 0s linear 0.15s;
  visibility: visible;
  opacity: 1;
}

#services-tab:hover .nav-item, #systems-tab:hover .nav-item, #learning-tab:hover .nav-item, #courses-tab:hover .nav-item, #school-tab:hover .nav-item {
  background-color: #f4f6f7;
}

.left-items .link-list a {
  text-decoration: underline;
}

.header-main .header-icon {
  width: 44px;
  height: 44px;
}

.left-side {
  width: 65%;
  padding-left: 175px;
  padding-right: 175px;
}

.line-height-1 {
  line-height: 1;
}

.right-side .right-side-wrap {
  width: 250px;
}

.right-side {
  width: 35%;
}

.right-side .right-side-wrap img {
  width: 250px;
  height: 150px;
  object-fit: cover;
}

.bubble-links a {
  padding: 6px 12px;
}

.header a.text-success:focus, .header a.text-success:hover {
  color: #292929 !important;
}

/* FOOTER */
footer {
  color: #D8EFE1;
  background: #2A4458;
  padding: 70px 0 0 0;
}

footer a {
  color: #D8EFE1;
  text-decoration: none;
}

footer a:hover {
  color: #fff;
}

footer h4 {
  padding-bottom: 5px;
  border-bottom: 1px solid #D8EFE1;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

footer .subtree div {
  margin-bottom: 4px;
}

footer .subtree a {
  display: inline-block;
  padding-bottom: 0;
  transition: all 0.4s ease-in-out 0s;
}

footer .subtree h4 a:hover {
  border-bottom: 0;
}

footer .social div img {
  margin-right: 10px;
  height: 55px;
  width: 55px;
}

footer .footerlogo {
  width: 200px;
}

/* APAVE STYLES */
.header-apave .header-top__logo::after {
  position: absolute;
  right: -2px;
  bottom: 0;
  content: " ";
  width: 55px;
  height: 100%;
  background: url("https://apave-diagnostics.com/css/components/assets/images/logo-border-right.png") no-repeat;
}

.header-apave .header-top__logo::before {
  position: absolute;
  left: 0px;
  bottom: 0;
  content: " ";
  width: 55px;
  height: 100%;
  background: url("https://apave-diagnostics.com/css/components/assets/images/logo-border-left.png") no-repeat;
}

.header-apave .bottom-part {
  height: 130px;
}

.header-apave .top-part .logo {
  max-height: 30px;
  width: 100px;
  object-fit: contain;
}

.header-apave .bottom-part .logo {
  height: auto;
  width: 120px;
  object-fit: contain;
  border-right: 2px solid #030f40;
}

.header-apave .active-tab {
  border-bottom: 3px solid #5bac5e;
}

.header-apave .white-line {
  height: 10px;
  box-shadow: 0 2px 8px 0 #adb5bd;
}

.header-apave .bottom-part a {
  text-decoration: none;
}

.header-apave .top-part a {
  text-decoration: none;
  color: #fff;
}

.header-apave .search-input {
  height: 36px;
  border: none;
}

.header-apave .dropdown-context_content {
  left: 50%;
  width: 100%;
  transform: translate(-50%, 10px);
  border-radius: 10px;
  min-width: max-content;
  padding: 10px;
  z-index: 9;
}

.header-apave .dropdown_content a:not(.logout-btn):hover {
  background-color: #f4f4f4;
}

.header-apave .hamburger span {
  width: 30px;
}

.header-apave.bg-header {
  height: 0;
}

@media only screen and (max-width: 800px) {
  footer {
    padding: 50px 0;
  }
  footer .social div img {
    margin-right: 0;
    height: 40px;
    width: 40px;
  }
}
@media only screen and (max-width: 1290px) {
  .header-eu .lang-item .right-item_label, .header-eu .search-label, .header-eu .lang {
    display: none;
  }
  .header-eu .right-items .right-item {
    padding: 8px 14px;
  }
  .header-eu .nav .nav-item {
    margin: 0;
    padding: 0 15px;
  }
  .header-eu .right-items .right-item.lang-item {
    padding-right: 15px;
  }
  .header-eu .dropdown-lang_content img {
    margin: 0 5px 0 15px;
  }
  .header-eu .bar-right ul li::after {
    padding-left: 9px;
  }
  .header-eu .bar-right ul li {
    padding: 0 5px;
  }
  .header-eu .left-items span {
    padding: 0 10px;
  }
  .header-eu .dropdown-lang_label {
    display: none !important;
  }
  .left-side {
    padding-right: 100px;
  }
}
@media only screen and (max-width: 1270px) {
  .lang-item .right-item_label, .search-label, .lang {
    display: none;
  }
  .right-items .right-item {
    padding: 8px 14px;
  }
  .header-main .nav .nav-item {
    margin: 0;
    padding: 0 15px;
  }
  .right-items .right-item.lang-item {
    padding-right: 15px;
  }
  .dropdown-lang_content img {
    margin: 0;
  }
  .dropdown-lang_label {
    display: none !important;
  }
  .bar-right ul li::after {
    padding-left: 9px;
  }
  .bar-right ul li {
    padding: 0 5px;
  }
  .header-eu .left-items span {
    padding: 0 6px;
  }
  .left-side {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media only screen and (max-width: 1100px) {
  .mobile {
    display: flex;
  }
  .header-main.mobile {
    display: flex;
  }
  .desktop {
    display: none;
  }
  .left-items span, .right-items li {
    height: auto;
  }
  .bar-right, .left-items, .right-items .right-item_label, .header::before, .lang-item {
    display: none;
  }
  .header-main {
    top: 0;
    box-shadow: none;
    height: 50px;
  }
  .header.scrolled {
    box-shadow: 1px 3px 10px #ddd;
  }
  .right-items li.dropdown-lang {
    display: none;
  }
  .mobile-menu {
    display: inline-block;
  }
  .right-items .search-item {
    border-left: none;
    z-index: 9;
    position: relative;
    background: #fff;
    padding: 10px 15px;
  }
  .header-main .logo img {
    height: 36px;
  }
  .right-items {
    line-height: 50px;
    top: 0;
    height: 50px;
  }
  .dropdown-mobile_content img {
    width: 20px;
    vertical-align: initial;
  }
  .search-input {
    width: 210px;
    font-size: 13px;
    margin-right: 5px;
    appearance: none;
  }
  .margin-special {
    margin: 70px auto 0;
  }
  .article-container .article-image {
    max-height: 250px;
  }
  .article-container .article-image img {
    width: 100%;
  }
  .cart_amount {
    color: #fff;
    padding-left: 3px;
  }
  .mobile-menu img {
    width: 24px;
  }
  .right-items .right-item.login-item {
    padding: 0 10px 0 2px;
  }
  .hamburger {
    display: block;
  }
  .header-main .logo {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    height: 50px;
    background: #fff;
  }
  .header-tg.mobile .logo {
    margin: 4px 0 0 0;
  }
  .header-topbar {
    top: 50px;
    z-index: 9;
    font-size: 12px;
  }
  .header-topbar .logged-in .intro {
    display: none;
  }
  .header-topbar .link {
    font-weight: 600;
    white-space: nowrap;
  }
  .header-topbar.mobile.logged-out .bar-left {
    padding: 11px 14px;
    display: block;
    height: auto;
  }
  .header-topbar.mobile.logged-in .bar-left {
    padding-left: 5px;
    display: flex;
  }
  .header-topbar .info {
    padding-left: 5px;
    padding-right: 5px;
    margin: 0;
    white-space: nowrap;
  }
  .right-items .right-item_img.search-icon img {
    width: 17px;
    margin-bottom: 3px;
  }
  .right-items li {
    display: inline;
  }
  .header-topbar .intro {
    margin: 0;
    display: inline-block;
    white-space: nowrap;
  }
  .right-side {
    width: 100%;
  }
  .left-side {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
  .header-apave.bg-header {
    height: 90px;
  }
  .dropdown-lang_label {
    display: block !important;
  }
}

/*# sourceMappingURL=headerFooter.css.map */
